<template>
    <div style="position:relative;" v-click-outside-element="calendar_hide">
        <input type="text" v-model="label" @click="calendar_show" readonly >
        <div class="lk-datepicker-calendar" v-if="view=='days'">
            <div class='lk-datepicker-calendar-header'>
                <div class="lk-datepicker-enable" @click="calendar_day_previous"><i class='fa fa-chevron-left'></i></div>
                <div class="lk-datepicker-enable" @click.stop="calendar_show_month">{{title}}</div>
                <div class="lk-datepicker-enable" @click="calendar_day_next"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div class="lk-datepicker-calendar-title-days">
                <div>{{lg.monday.substring(0,3)}}</div>
                <div>{{lg.tuesday.substring(0,3)}}</div>
                <div>{{lg.wednesday.substring(0,3)}}</div>
                <div>{{lg.thursday.substring(0,3)}}</div>
                <div>{{lg.friday.substring(0,3)}}</div>
                <div>{{lg.saturday.substring(0,3)}}</div>
                <div>{{lg.sunday.substring(0,3)}}</div>
            </div>
            <div class="lk-datepicker-calendar-days">
                <div class="lk-datepicker-calendar-day" v-for="d of days" :key="d.id" :class="d.style" @click="selected(d)">{{d.number}}</div>
            </div>
        </div>
        <div class='lk-datepicker-calendar' v-if="view=='months'">
            <div class='lk-datepicker-calendar-header'>
                <div class="lk-datepicker-enable" @click="calendar_month_previous"><i class='fa fa-chevron-left'></i></div>
                <div class="lk-datepicker-enable" @click.stop="calendar_show_year">{{title_month}}</div>
                <div class="lk-datepicker-enable" @click="calendar_month_next"><i class='fa fa-chevron-right'></i></div>
            </div>
            <div class="lk-datepicker-calendar-months">
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(1)">{{lg.january}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(2)">{{lg.february}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(3)">{{lg.march}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(4)">{{lg.april}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(5)">{{lg.may}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(6)">{{lg.june}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(7)">{{lg.july}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(8)">{{lg.august}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(9)">{{lg.september}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(10)">{{lg.october}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(11)">{{lg.november}}</div>
                <div class="lk-datepicker-calendar-month" @click.stop="calendar_month_set(12)">{{lg.december}}</div>
            </div>
        </div>
        <div class='lk-datepicker-calendar' v-if="view=='years'">
            <div :id="id" class="lk-datepicker-calendar-years">
                <div v-for="y of years" :key="y.number" class="lk-datepicker-calendar-year" @click.stop="calendar_year_set(y.number)">{{y.number}}</div>
            </div>
        </div>
    </div>    
</template>

<script>

import {DateTime} from 'luxon';
import moment from 'moment';

export default {
    props:{
        modelValue:String,
        weekends:Boolean,
        disabled_dates:Array,
        disabled:Boolean,
        changed:Function
    },
    emits:['update:modelValue'],
    watch:{
        modelValue:function(val){
            if (val!=undefined){
                this.tmpdate = val.substring(0,10);
                this.navdate = val.substring(0,10);
            }            
        }
    },
    data(){
        return {
            id:'',
            lang:'fr-FR',
            tmpdate:'',
            navdate:'',
            days:[],
            years:[],
            view:''
        };
    },
    computed:{
        lg: function(){
            return {
                monday:'Lundi',
                tuesday:'Mardi',
                wednesday:'Mercredi',
                thursday:'Jeudi',
                friday:'Vendredi',
                saturday:'Samedi',
                sunday:'Dimanche',
                january:'Janvier',
                february:'Février',
                march:'Mars',
                april:'Avril',
                may:'Mai',
                june:'Juin',
                july:'Juillet',
                august:'Août',
                september:'Septembre',
                october:'Octobre',
                november:'Novembre',
                december:'Décembre'
            };
        },
        label:function(){
            return DateTime.fromISO(this.tmpdate).toFormat('dd/MM/yyyy');
        },
        title:function(){
            return DateTime.fromISO(this.navdate).setLocale(this.lang).toFormat('MMM yyyy');
        },
        title_month:function(){
            return DateTime.fromISO(this.navdate).setLocale(this.lang).toFormat('yyyy');
        }
    },
    created() {
        var tmain = this;
        if (tmain.disabled_dates===undefined) { 
            tmain.disabled_dates=[]; 
        }
        tmain.id = 'app' + String(Math.random() * 10000000000000000);
        if (tmain.modelValue==undefined){
            tmain.tmpdate = DateTime.now().toFormat('yyyy-MM-dd');
            tmain.navdate = DateTime.now().toFormat('yyyy-MM-dd');
        }else{
            tmain.tmpdate = tmain.modelValue.substring(0,10);
            tmain.navdate = tmain.modelValue.substring(0,10);
        }
        tmain.years = [];
        for(var i=1900;i<=2100;i++){
            tmain.years.push({number:i});
        }
        tmain.refresh_ui();
    },
    methods:{
       calendar_show:function(){
            var tmain = this;
            if (!tmain.disabled){
                this.navdate = this.modelValue.substring(0,10);
                this.refresh_ui();
                this.view = 'days';
            }
       },
       calendar_show_month:function(){
            var tmain = this;
            if (!tmain.disabled){
                tmain.view = 'months';
            }
       },
       calendar_show_year:function(){
            var tmain = this;
            if (!tmain.disabled){
                tmain.view = 'years';
                setTimeout(function(){
                    var nb = ( Number(this.navdate.substring(0,4)) - 1902);
                    document.getElementById(this.id).scrollTop = (nb*43);
                }.bind(this),200);
            }
       },
       refresh_ui_year: function(){
           var nb = ( Number(this.navdate.substring(0,4)) - 1900);
            document.getElementById(this.id).scrollTop = (nb*43);
       },
       calendar_hide: function(){
            var tmain = this;
            tmain.view = '';
       },
       calendar_day_previous: function(){
           var tmain = this;
           tmain.navdate = DateTime.fromISO(tmain.navdate).minus({months:1}).toFormat('yyyy-MM-dd');
           tmain.refresh_ui();
       },
       calendar_day_next: function(){
           var tmain = this;
           tmain.navdate = DateTime.fromISO(tmain.navdate).plus({months:1}).toFormat('yyyy-MM-dd');
           tmain.refresh_ui();
       },
       calendar_month_previous: function(){
           var tmain = this;
           tmain.navdate = DateTime.fromISO(tmain.navdate).minus({years:1}).toFormat('yyyy-MM-dd');
       },
       calendar_month_next: function(){
           var tmain = this;
           tmain.navdate = DateTime.fromISO(tmain.navdate).plus({years:1}).toFormat('yyyy-MM-dd');
       },
       calendar_month_set: function(val){
           var tmain = this;
           this.view = 'days';
           tmain.navdate = DateTime.fromISO(tmain.navdate).set({months:val}).toFormat('yyyy-MM-dd');
           tmain.refresh_ui();
       },
       calendar_year_set: function(val){
           var tmain = this;
           this.view = 'months';
           tmain.navdate = DateTime.fromISO(tmain.navdate).set({years:val}).toFormat('yyyy-MM-dd');
           tmain.refresh_ui();
       },
       refresh_ui:function(){
           var days = [];
           var feries = this.rental_holidays_list();
           var nb = DateTime.fromISO(this.navdate).daysInMonth;
           var t = DateTime.fromISO(this.navdate).set({day:1}).weekday;
            for(var j=1;j<t;j++){
                days.push({id:'t'+j,number:'',vdate:'',style:"lk-datepicker-calendar-empty"});
            }
            for(var i=1;i<=nb;i++){
                var cdate=DateTime.fromISO(this.navdate).toFormat("yyyy-MM-")+(String(i).length==1 ? "0"+i : i);
                if (this.disabled_dates.indexOf(cdate)>-1 || feries.indexOf(cdate)>-1 || (DateTime.fromISO(cdate)<DateTime.now().minus({days:1})) || (this.weekends==false && (DateTime.fromISO(cdate).weekday==6 || DateTime.fromISO(cdate).weekday==7) )){
                    days.push({id:'n'+i,number:i,vdate:'',style:"lk-datepicker-calendar-noselect"});
                }else{
                    switch(cdate){
                        case DateTime.now().toFormat('yyyy-MM-dd'):
                            days.push({id:i,number:i,vdate:cdate,style:"lk-datepicker-calendar-today"});
                            break;
                        case this.tmpdate:
                            days.push({id:i,number:i,vdate:cdate,style:"lk-datepicker-calendar-select"});
                            break;
                        default:
                            days.push({id:i,number:i,vdate:cdate,style:"lk-datepicker-calendar-day"});
                            break;
                    }
                }
            }
            this.days = days;
        },
        selected:function(item){
            var tmain = this;
            if (item.vdate!=''){
                tmain.tmpdate = item.vdate;
                tmain.navdate = item.vdate;
                tmain.view='';
                tmain.$emit('update:modelValue',item.vdate);
                tmain.changed(item.vdate);
            }            
        },
        rental_holidays_list: function() {
            var datefin = "2050-12-31";
            var datedebut = "2022-01-01";
            var afin = moment(datefin).year();
            var adebut = moment(datedebut).year();
            var liste = [];

            for (var an = adebut; an <= afin; an++) {
                var G = an % 19;
                var C = Math.floor(an / 100);
                var H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30;
                var I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11));
                var J = (an * 1 + Math.floor(an / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
                var L = I - J;
                var MoisPaques = 3 + Math.floor((L + 40) / 44);
                var JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4);

                if (moment(an + "-01-01") >= moment(datedebut) && moment(an + "-01-01") <= moment(datefin)) {
                    liste.push(an + "-01-01");
                }

                if (moment(an + "-05-01") >= moment(datedebut) && moment(an + "-05-01") <= moment(datefin)) {
                    liste.push(an + "-05-01");
                }

                if (moment(an + "-05-08") >= moment(datedebut) && moment(an + "-05-08") <= moment(datefin)) {
                    liste.push(an + "-05-08");
                }

                if (moment(an + "-07-14") >= moment(datedebut) && moment(an + "-07-14") <= moment(datefin)) {
                    liste.push(an + "-07-14");
                }

                if (moment(an + "-08-15") >= moment(datedebut) && moment(an + "-08-15") <= moment(datefin)) {
                    liste.push(an + "-08-15");
                }

                if (moment(an + "-11-01") >= moment(datedebut) && moment(an + "-11-01") <= moment(datefin)) {
                    liste.push(an + "-11-01");
                }

                if (moment(an + "-11-11") >= moment(datedebut) && moment(an + "-11-11") <= moment(datefin)) {
                    liste.push(an + "-11-11");
                }

                if (moment(an + "-12-25") >= moment(datedebut) && moment(an + "-12-25") <= moment(datefin)) {
                    liste.push(an + "-12-25");
                }

                var paques = moment(an + "-" + MoisPaques + "-" + JourPaques);
                var compare1 = moment(paques).add(1, 'd'); //LundiPaques
                if (compare1 >= moment(datedebut) && compare1 <= moment(datefin)) {
                    liste.push(compare1.format("YYYY-MM-DD"));
                }

                var compare2 = moment(paques).add(39, 'd'); //JeudiAscension
                if (compare2 >= moment(datedebut) && compare2 <= moment(datefin)) {
                    liste.push(compare2.format("YYYY-MM-DD"));
                }

                var compare3 = moment(paques).add(50, 'd'); //LundiPentecote
                if (compare3 >= moment(datedebut) && compare3 <= moment(datefin)) {
                    liste.push(compare3.format("YYYY-MM-DD"));
                }
            }

            return liste;
        }
    }
}
</script>

<style>

.lk-datepicker-calendar{
    position:absolute;
    width:294px;
    height:auto;
    background-color:#fff;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #333;
    z-index:3;
    font-size:14px;
    color:#333;
}

.lk-datepicker-calendar-header{
    height:42px;
    width:100%;
    display:grid;
    grid-template-columns: 42px auto 42px;
}

.lk-datepicker-enable{
    cursor: pointer;
    text-align:center;
    line-height:42px;
}

.lk-datepicker-enable:hover{
    background-color:var(--C3);
}

.lk-datepicker-calendar-title-days {
    width:100%;
    height:32px;
    cursor:pointer;
    font-size:12px;
    display:grid;
    grid-template-columns: repeat(7,42px);
    justify-items:center;
}

.lk-datepicker-calendar-days {
    width:100%;
    cursor:pointer;
    display:grid;
    grid-template-columns: repeat(7,42px);    
}

.lk-datepicker-calendar-day {
    width:42px;
    height:42px;
    text-align:center;
    line-height:42px;
}

.lk-datepicker-calendar-today {
    width:43px;
    height:43px;
    line-height:43px;
    text-align:center;
    float:left;
    cursor:pointer;
    background-color:var(--C3);
    color:#333;
    border-radius:3px;
}

.lk-datepicker-calendar-select {
    width:43px;
    height:43px;
    line-height:43px;
    text-align:center;
    float:left;
    cursor:pointer;
    background-color:var(--C1);
    color:#fff;
    border-radius:3px;
}

.lk-datepicker-calendar-empty {
    width:43px;
    height:43px;
    line-height:43px;
    text-align:center;
    float:left;
}

.lk-datepicker-calendar-noselect {
    width:43px;
    height:43px;
    line-height:43px;
    text-align:center;
    float:left;
    color:#ccc;
}

.lk-datepicker-calendar-months {
    width:100%;
    cursor:pointer;
    display:grid;
    grid-template-columns: repeat(3,98px);    
}

.lk-datepicker-calendar-month {
    width:98px;
    height:42px;
    text-align:center;
    line-height:42px;
}

.lk-datepicker-calendar-header-left:hover, 
.lk-datepicker-calendar-header-center:hover, 
.lk-datepicker-calendar-header-right:hover, 
.lk-datepicker-calendar-day:hover,
.lk-datepicker-calendar-month:hover,
.lk-datepicker-calendar-year:hover {
    background-color: var(--C3);
}

.lk-datepicker-calendar-years {
    width:100%;
    height:300px;
    overflow-y:auto;
    overflow-x:hidden;
}

.lk-datepicker-calendar-year {
    width:100%;
    height:43px;
    line-height:43px;
    text-align:center;
    float:left;
    cursor:pointer;
}

</style>