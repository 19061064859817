import {reactive} from 'vue'
import {DateTime} from 'luxon';

export const store = reactive({
    title:'Location en ligne - Procust',
    document:{},
    get_document(){
        var value = {
            product:{},
            accessory:'00000000-0000-0000-0000-000000000000',
            article:'00000000-0000-0000-0000-000000000000',
            gridperiod:'',
            period:{
                id_agency:'c8a18d0a-8d86-44e4-968a-a0328f8003c0',
                rstartdate: DateTime.now().toFormat('yyyy-MM-dd'),
                rstarttime: "09:00",
                renddate: DateTime.now().toFormat('yyyy-MM-dd'),
                rendtime: "17:00"
            },
            codepromo:'',
            release:'00000000-0000-0000-0000-000000000000',
            return:'00000000-0000-0000-0000-000000000000'
        };
        if (DateTime.now().weekday==6) { value.period.rstartdate = DateTime.now().plus({days:2}); value.period.renddate = DateTime.now().plus({days:2}); }
        if (DateTime.now().weekday==7) { value.period.rstartdate = DateTime.now().plus({days:1}); value.period.renddate = DateTime.now().plus({days:1}); }
        if (localStorage.getItem("prdoc")!=null){
            value = JSON.parse(localStorage.getItem("prdoc"));
        }else{
            localStorage.setItem("prdoc",JSON.stringify(value));
        }
        this.document = value;
        return value; 
    },
    set_document(value){
        this.document = value;
        localStorage.setItem("prdoc",JSON.stringify(value));
    }
})