<template>
  <div class="boo-page">
    <div class="boo-header">
      <div class="boo-header-left">
        <!-- <a href="https://www.procust.fr" target="_blank"><img src="../assets/logo.png" alt=""></a> -->
      </div>
      <div class="boo-header-center">
        <a href="https://reservation.procust.fr" target="_blank"><img src="../assets/logo.png" alt=""></a>
      </div>
      <div class="boo-header-right">
        <!-- <ul>
          <li><i class='far fa-user'></i></li>
          <li><i class='fa fa-shopping-cart'></i></li>
        </ul> -->
      </div>
    </div>
    <div>
      <div class="boo-title">
        <h2>Page introuvable (404)</h2>
      </div>
    </div>    
    <div class="boo-footer-pub">
      <div class="boo-footer-pub-content">
        <div class="boo-footer-pub-item">
          <label><i class='fas fa-credit-card'></i> Paiement sécurisé par CB</label>
        </div>
        <div class="boo-footer-pub-item">
          <label><i class='fa fa-box'></i> Retrait et retour possible au cabinet</label>
        </div>
        <div class="boo-footer-pub-item">
          <label><i class='fa fa-truck'></i> Livraison et retour possible par transporteur</label>          
        </div>
      </div>      
    </div>
    <div class="boo-footer">
      <h2>Location en ligne</h2>
      <img src="../assets/logo_blanc.png" alt="">
      <h3>By Sud Orthopédie</h3>
      <div class="boo-footer-info">
        <div class="boo-footer-info-left">
          <ul>
            <li><a href="#" target="_blank">Mentions légales</a></li>
            <li><a href="#" target="_blank">Conditions Générales de Location</a></li>
            <li><a href="https://www.procust.fr" target="_blank">procust.fr</a></li>
          </ul>
        </div>
        <div class="boo-footer-info-right">
          <label>&copy; 2024 Procust | Site réalisé par <a href="https://www.lokisi.com" target="_blank">LoKisi</a></label>
        </div>
      </div>
    </div>
    <loadingbox v-if="loading"></loadingbox>
  </div>
</template>

<script>
export default {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
