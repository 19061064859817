import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {DateTime} from 'luxon'
import vueClickOutsideElement from 'vue-click-outside-element'
import VueMatomo from 'vue-matomo'
// const watchSrc = createDirective(({ el, value }) => {
//     el.addEventListener('load', () => {
//         if (value !== el.src) {
//             value.value = el.src;
//         }
//     });
// });

const app = createApp(App);
app.config.globalProperties.$filters = {
    money(value,lang){
        var vtype = "";
        switch(lang){
            case "fr-FR":
                vtype="EUR";
                break;
            default:
                vtype="EUR";
                break;
        }

        var formatter = new Intl.NumberFormat(lang, {style: 'currency',currency: vtype,minimumFractionDigits: 2,maximumFractionDigits:2});
        try{
            return formatter.format(value);
        }catch{
            return value;
        }
    },
    number(value,lang){
        var formatter = new Intl.NumberFormat(lang, {style: 'decimal',minimumFractionDigits: 2,maximumFractionDigits:2});
        try{
            return formatter.format(value);
        }catch{
            return value;
        }
    },
    mdate(value,lang){
        return DateTime.fromSQL(value,{locale:lang}).toLocaleString(DateTime.DATE_SHORT);
    },
    mtime(value,lang){
        return DateTime.fromSQL(value,{locale:lang}).toLocaleString("HH:mm");
    },
    mdatetime(value,lang){
        return DateTime.fromSQL(value,{locale:lang}).toLocaleString("DD/MM/YYYY HH:mm");
    },
    image(value){
        if (value!=''){
            try{
                var binary = '';
              var bytes = new Uint8Array(value);
              var len = bytes.byteLength;
              for (let i = 0; i < len; i++) {
                  binary += String.fromCharCode(bytes[i]);
              }
              return 'data:image/png;base64,'+btoa(binary);
            }catch(error){
                return "";
            } 
        }else{
            return "";
        }        
    }
}
app.directive('watch-src',(el,binding)=>{
    el.src = binding.value;
    el.addEventListener('load', () => {
        if (binding.value !== el.src) {
            binding.value = el.src;
        }
    });
});
app.use(vueClickOutsideElement);
app.use(router);
app.use(VueMatomo, {
    host: 'https://analytics.lokisi.rent',
    siteId: 4,
    router:router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    requireCookieConsent: false,
    enableHeartBeatTimer: false,
})
app.mount('#app');

window._paq.push(['trackPageView']);