class config {
    constructor() {
        
    }

    url() {
        return 'https://reservation.procust.fr';
    }
}

export default new config()