import axios from 'axios';
import config from './config';

class booking {
    constructor() {
        this.url = config.url();
    }

    async setting_get() {
        try {
            var data = {};
            var pkgheaders = {};
            var result = await axios.post(this.url + "/api/v3/setting", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async product_list(data) {
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v3/product/list2", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async delivery_list(data) {
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v3/delivery/list", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async promo(code) {
        try {
            var pkgheaders = { };
            var data = {codepromo:code};
            var result = await axios.post(this.url + "/api/v3/promo", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async product_refresh(data) {
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v3/product/refresh", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async document_create(document) {
        try {
            var data = document;
            var pkgheaders = { vtoken:sessionStorage.getItem('vtoken'),vkey: sessionStorage.getItem('vkey') };
            var result = await axios.post(this.url + "/api/v3/document/create2", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async document_validate(id, reference) {
        try {
            var data = { id, reference };
            var pkgheaders = { vtoken:sessionStorage.getItem('vtoken'),vkey: sessionStorage.getItem('vkey') };
            var result = await axios.post(this.url + "/api/v3/document/validate", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async document_cancel(id, reference) {
        try {
            var data = { id, reference };
            var pkgheaders = { vtoken:sessionStorage.getItem('vtoken'),vkey: sessionStorage.getItem('vkey') };
            var result = await axios.post(this.url + "/api/v3/document/cancel", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }    

    async media_download(id,app,filename){
        try{
            var data = {id:id,app:app,filename:filename}
            var pkgheaders = {  };
            var result = await axios.post(this.url + "/api/v3/media/download",data,{ responseType:'arraybuffer',credentials:false,headers:pkgheaders});
            return (result.status==200) ? result.data : {code:-1,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }

    async cg_download(filename){
        try{
            var data = {filename:filename}
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v3/media/cg/download",data,{ responseType:'arraybuffer',credentials:false,headers:pkgheaders});
            return (result.status==200) ? result.data : {code:-1,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }
}

export default new booking()