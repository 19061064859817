import axios from 'axios';
import config from './config';

class account {
    constructor() {
        this.url = config.url();
    }

    async create(form){
        try{
            var data = form;
            var result = await axios.post(this.url + "/api/v3/account/create",data,{ responseType:'json',credentials:false});
            return (result.status==200) ? result.data : {code:-2,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }

    async create_validate(email,code){
        try{
            var data = {email:email,code:code};
            var result = await axios.post(this.url + "/api/v3/account/create/validate",data,{ responseType:'json',credentials:false});
            return (result.status==200) ? result.data : {code:-2,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }

    async login(email,pwd){
        try{
            var data = { "login":email,"pwd":pwd };
            var pkgheaders = {};
            var result = await axios.post(this.url + "/api/v3/account/login",data,{ responseType:'json',credentials:false, headers: pkgheaders});
            sessionStorage.setItem("vtoken",result.data.data.vtoken);
            sessionStorage.setItem("vkey",result.data.data.vkey);
            return (result.status==200) ? result.data : {code:-1,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }

    async logout(){
        try{
            var data = { };
            var pkgheaders = { vtoken:sessionStorage.getItem('vtoken'),vkey: sessionStorage.getItem('vkey') };
            var result = await axios.post(this.url + "/api/v3/account/logout",data,{ responseType:'json',credentials:false,headers:pkgheaders});
            return (result.status==200) ? result.data : {code:-1,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }

    async info(){
        try{
            var data = { };
            var pkgheaders = { vtoken:sessionStorage.getItem('vtoken'),vkey: sessionStorage.getItem('vkey') };
            var result = await axios.post(this.url + "/api/v3/account/info",data,{ responseType:'json',credentials:false,headers:pkgheaders});
            return (result.status==200) ? result.data : {code:-1,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }

    async update(account){
        try{
            var data = account;
            var pkgheaders = { vtoken:sessionStorage.getItem('vtoken'),vkey: sessionStorage.getItem('vkey') };
            var result = await axios.post(this.url + "/api/v3/account/update",data,{ responseType:'json',credentials:false,headers:pkgheaders});
            return (result.status==200) ? result.data : {code:-1,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }

    async recovery(email){
        try{
            var data = { "email":email};
            var pkgheaders = {};
            var result = await axios.post(this.url + "/api/v3/account/recovery",data,{ responseType:'json',credentials:false,headers:pkgheaders});
            return (result.status==200) ? result.data : {code:-1,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }

    async recovery_validate(code,pwd){
        try{
            var data = { code:code,pwd:pwd };
            var pkgheaders = {};
            var result = await axios.post(this.url + "/api/v3/account/recovery/validate",data,{ responseType:'json',credentials:false,headers:pkgheaders});
            return (result.status==200) ? result.data : {code:-1,message:''};
        }catch(err){
            return {code:-1,message:err};
        }
    }
}

export default new account()