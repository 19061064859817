<template>
  <div class="boo-page">
    <div class="boo-header">
      <div class="boo-header-left">
        <!-- <a href="https://www.procust.fr" target="_blank"><img src="../assets/logo.png" alt=""></a> -->
      </div>
      <div class="boo-header-center">
        <a href="https://www.procust.fr" target="_blank"><img src="../assets/logo.png" alt=""></a>
      </div>
      <div class="boo-header-right">
        <!-- <ul>
          <li><i class='far fa-user'></i></li>
          <li><i class='fa fa-shopping-cart'></i></li>
        </ul> -->
      </div>
    </div>
    <div>
      <div class="boo-basket-header">
        <button v-if="view=='basket'" @click="return_selection()"><i class='fa fa-arrow-left'></i> Retour à la sélection</button>
        <button v-if="view!='basket' && view!='basket_payment'" @click="nav('basket')"><i class='fa fa-arrow-left'></i> Retour au panier</button>
      </div>
      <div class="boo-basket">
        <div v-if="view=='basket'" class="boo-basket-content">
          <h2>Panier</h2>
          <div class="boo-basket-table">
            <div class="boo-basket-table-header">
              <div class="boo-basket-table-header-column-left"><label>Produit</label></div>
              <div class="boo-basket-table-header-column-right"><label>Prix unitaire</label></div>
              <div class="boo-basket-table-header-column-center"><label>Quantité</label></div>
              <div class="boo-basket-table-header-column-right"><label>Prix total</label></div>
              <div class="boo-basket-table-header-column-left"><label></label></div>
            </div>
            <div class="boo-basket-table-content">
              <div class="boo-basket-table-content-product">
                <img :src="product.logo" alt="">
                <div class="boo-basket-table-content-product-info">
                  <h3>{{product.title}}</h3>
                  <label>Date de départ : <b>{{$filters.mdate(period.rstartdate,lang)}} à {{ period.rstarttime }}</b></label>
                  <label>Date de retour : <b>{{$filters.mdate(period.renddate,lang)}} à {{ period.rendtime }}</b></label>
                  <label v-if="accessory.total_line==0">Type d'enveloppe : <b>{{accessory.note}}</b></label>
                </div>
              </div>
              <div class="boo-basket-table-column-right"><label>{{$filters.money(product.total_line,lang)}}</label></div>
              <div class="boo-basket-table-column-center"><label>1</label></div>
              <div class="boo-basket-table-column-right"><label><b>{{$filters.money(product.total_line,lang)}}</b></label></div>
              <div class="boo-basket-table-column-center"><label><i class='fa fa-times'></i></label></div>
            </div>
            <div class="boo-basket-table-content">
              <div class="boo-basket-table-content-product">
                <div style="height:40px;"></div>
                <div class="boo-basket-table-content-product-info" style="align-self:center;">
                  <h3>{{accessory.note}}</h3>
                </div>
              </div>
              <div class="boo-basket-table-column-right"><label>{{$filters.money(accessory.total_line,lang)}}</label></div>
              <div class="boo-basket-table-column-center"><label>1</label></div>
              <div class="boo-basket-table-column-right"><label><b>{{$filters.money(accessory.total_line,lang)}}</b></label></div>
              <div class="boo-basket-table-column-center"><label><i class='fa fa-times'></i></label></div>
            </div>
          </div>
          <div class="boo-basket-promo">
            <h2>Ajouter un code promo</h2>
            <div class="boo-basket-promo-input">
              <input v-model="promo_value" type="text" placeholder="Code Promo">
              <button @click="promo_set()"><i class="fa fa-check"></i></button>
            </div>
          </div>
          <div class="boo-formulaire-content">
            <h4>Veuillez renseigner votre <b>mode de livraison</b> et votre <b>mode de retour</b> afin de poursuivre votre commande.</h4>
          </div>          
          <div class="boo-basket-transport">
            <div class="boo-basket-transport-column">
              <h2>Mode de livraison</h2>
              <div class="boo-basket-transport-radio" v-for="rel of releases" :key="rel.id">
                <input type="radio" name="delivery" :id="rel.id" :value="rel.id" v-model="release_value" @change="delivery_select()">
                <label :for="rel.id">{{rel.description}} - <b>{{$filters.money(rel.selling_price,lang)}}</b></label>
              </div>
            </div>
            <div class="boo-basket-transport-column">
              <h2>Mode de retour</h2>
              <div class="boo-basket-transport-radio" v-for="ret of returns" :key="ret.id">
                <input type="radio" name="return" :id="ret.id" :value="ret.id" v-model="return_value" @change="delivery_select()">
                <label :for="ret.id">{{ret.description}} - <b>{{$filters.money(ret.selling_price,lang)}}</b></label>
              </div>
            </div>
          </div>
        </div>
        <div v-if="view=='basket_login'" class="boo-basket-content">
          <h2>Compte</h2>         
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <h2>Veuillez vous authentifier</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>E-mail</label>
                    <input type="text" v-model="account.email">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Mot de passe</label>
                    <input type="password" v-model="account.pwd" v-on:keyup.enter="btn_account_login()">
                  </div>
              </div>
              <label @click="nav('basket_account_recovery')"><a style="color:var(--C1)">Mot de passe oublié ?</a></label>
              <button @click="btn_account_login()" style="margin-top:20px;">Connexion</button>
              <label style="color: red;white-space: pre-wrap;padding: 15px;background-color: #fde1e1;margin-top: 20px;border-radius: 10px;" v-if="error_message_account!=''">{{ error_message_account }}</label>

              <h2 style="padding-top:30px;">Vous n'avez pas de compte Procust ?</h2>
              <button @click="nav('basket_account_create')" style="margin-top:20px;">Créer un compte</button>
            </div>
          </div>
        </div>
        <div v-if="view=='basket_login_valid'" class="boo-basket-content">
          <h2>Compte</h2>         
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <h2>Adresse de facturation</h2>
              <h3>{{account.billingcompany}}</h3>
              <label>{{account.billingaddress1}}</label>
              <label>{{account.billingzipcode}} {{account.billingcity}}</label>
              <label>Téléphone : {{account.billingphone}}</label>
              <label>Courriel : {{account.email}}</label>
              <h2 style="padding-top:15px;">Nom du praticien</h2>
              <label>{{account.dynfield1}}</label>
            </div>
            <div class="boo-basket-account-column">
              <h2>Adresse de livraison</h2>
              <h3>{{account.deliverycompany}}</h3>
              <label>{{account.deliveryaddress1}}</label>
              <label>{{account.deliveryzipcode}} {{account.deliverycity}}</label>
              <label>Téléphone : {{account.deliveryphone}}</label>
            </div>
          </div>
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <button @click="nav('basket_account_edit')" style="display:block;margin-top:20px;">Modifier vos informations</button>
              <button @click="btn_account_logout()" style="margin-top:20px;">Déconnexion</button>
            </div>
          </div>
        </div>
        <div v-if="view=='basket_account_edit'" class="boo-basket-content">
          <h2>Compte</h2>         
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <h2>Adresse de facturation</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Raison sociale (Professionnel) - Nom Prénom (Particulier)</label>
                    <input type="text" v-model="account.billingcompany">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Numéro et nom de rue</label>
                    <input type="text" v-model="account.billingaddress1">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Adresse complémentaire</label>
                    <input type="text" v-model="account.billingaddress2">
                  </div>
              </div>
              <div class="boo-form-edit-line-2">
                  <div class="boo-form-input">
                    <label>Code postal</label>
                    <input type="text" v-model="account.billingzipcode">
                  </div>
                  <div class="boo-form-input">
                    <label>Ville</label>
                    <input type="text" v-model="account.billingcity">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Pays</label>
                    <input type="text" v-model="account.billingcountry">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Téléphone</label>
                    <input type="text" v-model="account.billingphone">
                  </div>
              </div>
            </div>
            <div class="boo-basket-account-column">
              <h2>Adresse de livraison</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Raison sociale (Professionnel) - Nom Prénom (Particulier)</label>
                    <input type="text" v-model="account.deliverycompany">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Numéro et nom de rue</label>
                    <input type="text" v-model="account.deliveryaddress1">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Adresse complémentaire</label>
                    <input type="text" v-model="account.deliveryaddress2">
                  </div>
              </div>
              <div class="boo-form-edit-line-2">
                  <div class="boo-form-input">
                    <label>Code postal</label>
                    <input type="text" v-model="account.deliveryzipcode">
                  </div>
                  <div class="boo-form-input">
                    <label>Ville</label>
                    <input type="text" v-model="account.deliverycity">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Pays</label>
                    <input type="text" v-model="account.deliverycountry">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Téléphone</label>
                    <input type="text" v-model="account.deliveryphone">
                  </div>
              </div>
            </div>
          </div>
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <button @click="btn_account_edit_validate()" style="margin-top:20px;">Enregistrer</button>
              <label style="color: red;white-space: pre-wrap;padding: 15px;background-color: #fde1e1;margin-top: 20px;border-radius: 10px;" v-if="error_message_account!=''">{{ error_message_account }}</label>
            </div>
          </div>
        </div>
        <div v-if="view=='basket_account_recovery'" class="boo-basket-content">
          <h2>Compte</h2>         
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <h2>Veuillez saisir votre adresse email</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>E-mail</label>
                    <input type="text" v-model="account.email">
                  </div>
              </div>
              <button @click="btn_account_recovery_edit()" style="margin-top:20px;">Demande de réinitialisation</button>
              <label style="color: red;white-space: pre-wrap;padding: 15px;background-color: #fde1e1;margin-top: 20px;border-radius: 10px;" v-if="error_message_account!=''">{{ error_message_account }}</label>
            </div>
          </div>
        </div>
        <div v-if="view=='basket_account_recovery_valid'" class="boo-basket-content">
          <h2>Compte</h2>         
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <h2>Veuillez renseigner votre nouveau mot de passe</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Nouveau mot de passe</label>
                    <input type="password" v-model="account.pwd">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Code de validation (reçu par mail)</label>
                    <input type="text" v-model="recoverytoken" v-on:keyup.enter="btn_account_recovery_valid()">
                  </div>
              </div>
              <button @click="btn_account_recovery_valid()" style="margin-top:20px;">Valider votre mot de passe</button>
              <label style="color: red;white-space: pre-wrap;padding: 15px;background-color: #fde1e1;margin-top: 20px;border-radius: 10px;" v-if="error_message_account!=''">{{ error_message_account }}</label>
            </div>
          </div>
        </div>
        <div v-if="view=='basket_account_create'" class="boo-basket-content">
          <h2>Compte</h2>         
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <h2>Identifiants</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>E-mail</label>
                    <input type="email" v-model="account.email">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Mot de passe</label>
                    <input type="password" v-model="account.pwd">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Confirmer le mot de passe</label>
                    <input type="password" v-model="pwdconfirm">
                  </div>
              </div>
            </div>
          </div>          
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <h2>Adresse de facturation</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Raison sociale (Professionnel) - Nom Prénom (Particulier)</label>
                    <input type="text" v-model="account.company">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Numéro et nom de rue</label>
                    <input type="text" v-model="account.address1">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Adresse complémentaire</label>
                    <input type="text" v-model="account.address2">
                  </div>
              </div>
              <div class="boo-form-edit-line-2">
                  <div class="boo-form-input">
                    <label>Code postal</label>
                    <input type="text" v-model="account.zipcode">
                  </div>
                  <div class="boo-form-input">
                    <label>Ville</label>
                    <input type="text" v-model="account.city">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Pays</label>
                    <input type="text" v-model="account.country">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Téléphone</label>
                    <input type="text" v-model="account.phone">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Votre adresse de livraison est différente ?</label>
                    <div class="boo-form-input-checkbox" @click="account_form_create_delivery_diff=(account_form_create_delivery_diff==1 ? 0 : 1)">
                      <i v-if="account_form_create_delivery_diff==1" class="fa fa-check"></i>
                    </div>
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Nom du praticien vous ayant recommandé la cryothérapie</label>
                    <input type="text" v-model="account.dynfield1">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Comment vous nous connaissez ?</label>
                    <input type="text" v-model="account.dynfield2">
                  </div>
              </div>
              <button @click="btn_account_create()" style="margin-top:20px;">Créer le compte</button>
              <label style="color: red;white-space: pre-wrap;padding: 15px;background-color: #fde1e1;margin-top: 20px;border-radius: 10px;" v-if="error_message_account!=''">{{ error_message_account }}</label>
            </div>
            <div v-if="account_form_create_delivery_diff==1" class="boo-basket-account-column">
              <h2>Adresse de livraison</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Raison sociale (Professionnel) - Nom Prénom (Particulier)</label>
                    <input type="text" v-model="account.deliverycompany">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Numéro et nom de rue</label>
                    <input type="text" v-model="account.deliveryaddress1">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Adresse complémentaire</label>
                    <input type="text" v-model="account.deliveryaddress2">
                  </div>
              </div>
              <div class="boo-form-edit-line-2">
                  <div class="boo-form-input">
                    <label>Code postal</label>
                    <input type="text" v-model="account.deliveryzipcode">
                  </div>
                  <div class="boo-form-input">
                    <label>Ville</label>
                    <input type="text" v-model="account.deliverycity">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Pays</label>
                    <input type="text" v-model="account.deliverycountry">
                  </div>
              </div>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Téléphone</label>
                    <input type="text" v-model="account.deliveryphone">
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="view=='basket_account_create_valid'" class="boo-basket-content">
          <h2>Compte</h2>
          <div class="boo-basket-account">
            <div class="boo-basket-account-column">
              <h2>Validation du compte</h2>
              <div class="boo-form-edit-line-1">
                  <div class="boo-form-input">
                    <label>Saisir le code de validation envoyé par mail à {{ account.email }}</label>
                    <input type="text" v-model="createtoken" placeholder="Code de validation">
                  </div>
              </div>
              <button @click="btn_account_create_validate()" style="margin-top:20px;">Valider le compte</button>
              <label style="color: red;white-space: pre-wrap;padding: 15px;background-color: #fde1e1;margin-top: 20px;border-radius: 10px;" v-if="error_message_account!=''">{{ error_message_account }}</label>
            </div>
          </div>
        </div>
        <div v-if="view=='basket_payment'" class="boo-basket-content">
          <h2>Effectuer votre paiement</h2>
          <iframe ref="iSwikly" @load="registerIframeEvents" style="width:100%;height:800px;border: none" :src="swikly_url"></iframe>
        </div>
        <div class="boo-basket-recap">
          <h2>Récapitulatif</h2>
          <div class="boo-basket-recap-content">
            <div class="boo-basket-recap-content-line">
              <label>{{product.title}} ({{product.duration_coef}} jours)<br/>du {{ $filters.mdate(period.rstartdate) }} à {{ period.rstarttime }}<br/>du {{ $filters.mdate(period.renddate) }} à {{ period.rendtime }}</label>              
              <span>{{$filters.money(product.total_line,lang)}}</span>
            </div>
            <div v-if="promo_total!=0" class="boo-basket-recap-content-line">
              <label><b>{{promo.description}}</b></label>              
              <span><b>- {{$filters.money(promo_total,lang)}}</b></span>
            </div>
            <div v-if="accessory.total_line>0" class="boo-basket-recap-content-line">
              <label>{{accessory.note}}</label>              
              <span>{{$filters.money(accessory.total_line,lang)}}</span>
            </div>
            <div class="boo-basket-recap-content-line">
              <label>Livraison:<span v-if="release_selected.id!='00000000-0000-0000-0000-000000000000'">{{ release_selected.description }}</span></label>
              <span>{{$filters.money(release_selected.selling_price,lang)}}</span>
            </div>
            <div class="boo-basket-recap-content-line">
              <label>Retour:<span v-if="return_selected.id!='00000000-0000-0000-0000-000000000000'">{{ return_selected.description }}</span></label>
              <span>{{$filters.money(return_selected.selling_price,lang)}}</span>
            </div>
            <div class="boo-basket-recap-content-line-total">
              <label>Total (TVA incluse)</label>
              <span>{{ $filters.money(total,lang) }}</span>
            </div>
            <div class="boo-basket-recap-content-line">
              <label>Caution:<span>Une empreinte bancaire sera faite sur votre carte au moment du paiement. Vous ne serez pas débité.</span></label>
              <span>{{$filters.money(1000,lang)}}</span>
            </div>
            <div class="boo-basket-recap-error" v-if="error_message!=''">
              <label>{{ error_message }}</label>
            </div>
            <div class="boo-basket-recap-content-line-action">
              <button v-if="view=='basket'" @click="btn_command_next()">Poursuivre la commande</button>
              <div  v-if="view!='basket'" class="boo-basket-recap-cgl">
                <div @click="accept_cgl=(accept_cgl==0 ? 1 :0 )" class="boo-basket-recap-cgl-case">
                  <i v-if="accept_cgl==1" class="fas fa-check"></i>
                </div>
                <div class="boo-basket-recap-cgl-text">
                  <label>J'accepte les <a href="https://reservation.procust.fr/procust_cgl.pdf" target="_blank">conditions générales de location</a></label>
                </div>
              </div>
              <button v-if="!(view=='basket_login_valid' && accept_cgl==1) && view!='basket'" class="button-disabled">Effectuer le paiement</button>
              <button v-if="view=='basket_login_valid' && accept_cgl==1" @click="btn_payment_create()">Effectuer le paiement</button>
              <!-- <button v-if="view=='basket_payment'" @click="btn_payment_cancel()">Annuler le paiement</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>    
    <div class="boo-footer-pub">
      <div class="boo-footer-pub-content">
        <div class="boo-footer-pub-item">
          <label><i class='fas fa-credit-card'></i> Paiement sécurisé par CB</label>
        </div>
        <div class="boo-footer-pub-item">
          <label><i class='fa fa-box'></i> Retrait et retour possible au cabinet</label>
        </div>
        <div class="boo-footer-pub-item">
          <label><i class='fa fa-truck'></i> Livraison et retour possible par transporteur</label>          
        </div>
      </div>      
    </div>
    <div class="boo-footer">
      <h2>Location en ligne</h2>
      <img src="../assets/logo_blanc.png" alt="">
      <h3>By Sud Orthopédie</h3>
      <div class="boo-footer-info">
        <div class="boo-footer-info-left">
          <ul>
            <li><a href="#" target="_blank">Mentions légales</a></li>
            <li><a href="#" target="_blank">Conditions Générales de Location</a></li>
            <li><a href="https://www.procust.fr" target="_blank">procust.fr</a></li>
          </ul>
        </div>
        <div class="boo-footer-info-right">
          <label>&copy; 2024 Procust | Site réalisé par <a href="https://www.lokisi.com" target="_blank">LoKisi</a></label>
        </div>
      </div>
    </div>
    <loadingbox v-if="loading"></loadingbox>
  </div>
</template>

<script>
import translation from "../core/data/lang";
import loadingbox from "../core/controls/ui-loadingbox";
import apiaccount from "../core/data/account";
import apibooking from "../core/data/booking";
import {store} from "../core/data/store";

export default {
  components:{ 
    loadingbox
  },
  mounted() {
    window.addEventListener('message', this.handleIframeMessage);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleIframeMessage);
  },
  data(){
    return {
      loading:false,
      resweb: {},
      lang:'fr-FR',
      view:'basket',
      products:[],
      product:{},
      accessory:'',
      period:{},
      releases:[],
      returns:[],
      promo_value:'',
      promo:{},
      release_value:'00000000-0000-0000-0000-000000000000',
      return_value:'00000000-0000-0000-0000-000000000000',
      error_message:'',
      error_message_account:'',
      account_form_create_delivery_diff:0,
      createtoken:'',
      recoverytoken:'',
      pwdconfirm:'',
      accept_cgl:0,
      account:{
        email:'',
        pwd:'',
        company:'',
        address1:'',
        address2:'',
        zipcode:'',
        city:'',
        country:'',
        phone:'',
        deliverycompany:'',
        deliveryaddress1:'',
        deliveryaddress2:'',
        deliveryzipcode:'',
        deliverycity:'',
        deliverycountry:'',
        deliveryphone:'',
        dynfield1:'',
        dynfield2:''
      },
      link_url:'',
      swikly_url:''
    }
  },
  computed: {
    lg: function(){
      return translation.get(this.lang);
    },
    promo_total: function(){
      var value = 0;
      if (JSON.stringify(this.promo)!='{}'){
        value = this.promo.selling_price * this.product.duration_coef;
      }
      return value;
    },
    total:function(){
      return (this.product.total_line - this.promo_total) + this.accessory.total_line + this.release_selected.selling_price + this.return_selected.selling_price;
    },
    // total: function(){
    //   return (this.product.total_line - this.promo_total) + this.release_selected.selling_price + this.return_selected.selling_price;
    // },
    release_selected: function(){
      var value = {id:'00000000-0000-0000-0000-000000000000',description:'',selling_price:0};
      if (this.releases.length>0 && this.release_value!='00000000-0000-0000-0000-000000000000'){
        value = this.releases.find(x=>x.id==this.release_value);
      }
      return value;
    },
    return_selected: function(){
      var value = {id:'00000000-0000-0000-0000-000000000000',description:'',selling_price:0};
      if (this.returns.length>0 && this.return_value!='00000000-0000-0000-0000-000000000000'){
        value = this.returns.find(x=>x.id==this.return_value);
      }
      return value;
    },
    phone_calculated: function(){
      var texte = this.account.phone.replace(/ /g,'');

      if (texte.startsWith('+')==false){
        texte = "+33"+texte.substring(1,10);
      }
      return texte;
    }
  },
  created: async function(){
    var tmain = this;
    store.get_document();
    if (JSON.stringify(store.document.product)=='{}'){
      tmain.$router.push({path:'/'});
    }else{
      document.title = store.title;
      tmain.product = store.document.product;
      if (store.document.accessory!='00000000-0000-0000-0000-000000000000'){
        tmain.accessory = store.document.product.accessories.find(x=>x.id==store.document.accessory);
      }
      if (store.document.article!='00000000-0000-0000-0000-000000000000'){
        tmain.accessory = store.document.product.articles.find(x=>x.id==store.document.article);
      }
      tmain.period = store.document.period;
      await this.refresh_ui();    
    } 
  },
  methods:{
    handleIframeMessage(event) {
        if (event.data && event.data.type === 'swikly_changeURL') {
            const newURL = event.data.url;
            window.location.href = newURL;
        }
    },
    nav: function(value){
      this.view = value;
    },
    refresh_ui: async function(){
      var tmain = this;

      var rep = await apibooking.product_list(store.document.period);
      if (rep.code==0){
        tmain.product = rep.data.products.find(x=>x.id==tmain.product.id);
        if (tmain.product.duration_coef<0){
          tmain.product.duration_coef = 0;
          tmain.product.total_line = 0;
        }
        var rep2 = await apibooking.delivery_list();
        this.releases = rep2.data.releases;
        this.returns = rep2.data.returns;
        this.release_value = store.document.release;
        this.return_value = store.document.return;        
        if (store.document.promo!=''){
          var rep3 = await apibooking.promo(store.document.promo);
          if (rep3.data.length>0){
            this.promo = rep3.data[0];
          }
        }        
      }
    },
    promo_set: async function(){
      store.document.promo = this.promo_value;
      store.set_document(store.document);
      this.promo_value = "";
      await this.refresh_ui();
    },
    delivery_select: async function(){
      store.document.release = this.release_value;
      store.document.return = this.return_value;
      store.set_document(store.document);
    },
    return_selection: async function(){
      var tmain = this;
      store.document.accessory = '00000000-0000-0000-0000-000000000000';
      store.document.return = '00000000-0000-0000-0000-000000000000';
      store.document.release = '00000000-0000-0000-0000-000000000000';
      store.set_document(store.document);
      tmain.$router.push({path:'/item'});
    },
    btn_command_next: async function(){
      var tmain = this;
      tmain.error_message = "";
      if ((tmain.release_selected.id!='00000000-0000-0000-0000-000000000000' && tmain.return_selected.id!='00000000-0000-0000-0000-000000000000')){
        var rep = await apiaccount.info();
        if (rep.code==0){
          tmain.account = rep.data;
          tmain.view = "basket_login_valid"
        }else{
          tmain.view='basket_login';
        }        
      }else{
        tmain.error_message = "Veuillez sélectionner un mode de livraison et un mode de retour afin de poursuivre votre commande";
      }
    },
    btn_account_login: async function(){
      var tmain = this;
      tmain.error_message_account = "";
      var rep = await apiaccount.login(tmain.account.email,tmain.account.pwd);
      if (rep.code==0){
        var rep2 = await apiaccount.info();
        tmain.account = rep2.data;
        tmain.view = "basket_login_valid";
      }else{
        tmain.error_message_account = "Vos identifiants sont incorrects";
      }
    },
    btn_account_logout: async function(){
      var tmain = this;
      await apiaccount.logout();
      sessionStorage.clear();
      tmain.account={
        email:'',
        pwd:'',
        company:'',
        address1:'',
        address2:'',
        zipcode:'',
        city:'',
        country:'',
        phone:'',
        deliverycompany:'',
        deliveryaddress1:'',
        deliveryaddress2:'',
        deliveryzipcode:'',
        deliverycity:'',
        deliverycountry:'',
        deliveryphone:'',
        dynfield1:'',
        dynfield2:''
      };
      tmain.view = "basket_login";
    },
    btn_account_edit_validate: async function(){
      var tmain = this;
      tmain.error_message_account = "";
      tmain.error_message_account += (tmain.account.billingcompany=='' ? "Veuillez renseigner une raison sociale ou bien un nom dans l'adresse de facturation.\r\n" : "");
      tmain.error_message_account += (tmain.account.deliverycompany=='' ? "Veuillez renseigner une raison sociale ou bien un nom dans l'adresse de livraison.\r\n" : "");
      if (tmain.error_message_account==''){
        var rep = await apiaccount.update(tmain.account);
        if (rep.code == 0){
          tmain.view = "basket_login_valid";
        }else{
          tmain.error_message_account = "Une erreur est survenue, veuillez contacter Procust";
        }
      }      
    },
    btn_account_recovery_edit: async function(){
      var tmain = this;
      tmain.error_message_account = "";
      tmain.recoverytoken = "";
      tmain.account.pwd = "";
      var rep = await apiaccount.recovery(tmain.account.email);
      if (rep.code==0){
        tmain.view = "basket_account_recovery_valid";
      }else{
        tmain.error_message_account = "Votre adresse email n'est pas reconnu.";
      }
    },
    btn_account_recovery_valid: async function(){
      var tmain = this;
      tmain.error_message_account = "";
      var rep = await apiaccount.recovery_validate(tmain.recoverytoken,tmain.account.pwd);
      if (rep.code==0){
        tmain.recoverytoken = "";
        tmain.account.pwd = "";
        tmain.view = "basket_login";
      }else{
        if (rep.code==-1){ tmain.error_message_account = "Code de vérification incorrect."; }
        if (rep.code==-2){ tmain.error_message_account = "Votre mot de passe doit contenir au minimum 8 caractères dont des majuscules, des chiffres et des caractères spéciaux."; }
      }
    },
    btn_account_create: async function(){
      var tmain = this;
      tmain.error_message_account = "";
      tmain.error_message_account = (tmain.account.email=='' ? "Veuillez renseigner adresse e-mail.\r\n":"");
      tmain.error_message_account += (tmain.account.company=='' ? "Veuillez renseigner votre nom, prénom ou raison sociale.\r\n":"");
      tmain.error_message_account += (tmain.account.address1=='' ? "Veuillez renseigner votre rue.\r\n":"");
      tmain.error_message_account += (tmain.account.zipcode=='' ? "Veuillez renseigner votre code postal.\r\n":"");
      tmain.error_message_account += (tmain.account.city=='' ? "Veuillez renseigner votre ville.\r\n":"");
      if (tmain.error_message_account==''){
        if (tmain.pwdconfirm==tmain.account.pwd){
          if (tmain.account_form_create_delivery_diff==0){
            tmain.account.deliverycompany = tmain.account.company;
            tmain.account.deliveryaddress1 = tmain.account.address1;
            tmain.account.deliveryaddress2 = tmain.account.address2;
            tmain.account.deliveryzipcode = tmain.account.zipcode;
            tmain.account.deliverycity = tmain.account.city;
            tmain.account.deliverycountry = tmain.account.country;
            tmain.account.deliveryphone = tmain.account.phone;
          }
          var rep = await apiaccount.create(tmain.account);
          if (rep.code==0){
            tmain.pwdconfirm ="";
            tmain.createtoken = "";
            tmain.view = 'basket_account_create_valid';
          }else{
            tmain.error_message_account = rep.message;
          }
        }else{
          tmain.error_message_account = "Vos mots de passe sont différents";
        }
      }
    },
    btn_account_create_validate: async function(){
      var tmain = this;
      if (tmain.createtoken!=''){
        var rep = await apiaccount.create_validate(tmain.account.email,tmain.createtoken);
        if (rep.code==0){
          var txt = tmain.account.email;
          tmain.account = {
            email:txt,
            pwd:'',
            company:'',
            address1:'',
            address2:'',
            zipcode:'',
            city:'',
            country:'',
            phone:'',
            deliverycompany:'',
            deliveryaddress1:'',
            deliveryaddress2:'',
            deliveryzipcode:'',
            deliverycity:'',
            deliverycountry:'',
            deliveryphone:'',
            dynfield1:'',
            dynfield2:''
          }
          tmain.view='basket_login';
        }else{
          tmain.error_message_account = "Veuillez saisir un code valide";  
        }
      }else{
        tmain.error_message_account = "Veuillez saisir un code valide";
      }
    },
    btn_payment_create: async function(){
      var tmain = this;
      this.loading = true;
      let boopayment = await apibooking.document_create(store.document);
      this.loading=false;      
      if (boopayment.code==0){
        tmain.view = "basket_payment";
        tmain.loading=true;
        tmain.swikly_url = boopayment.data.url;
        tmain.loading=false;
        setTimeout(function(){
          //document.getElementsByClassName('boo-page')[0].scrollTo(0,0);
        },300);
      }
    }
  }
}
</script>