class lang {
    constructor() {
        
    }

    get(value) {
        switch(value){
            default:
                return {
                    "LBL_SALE":"Devis de vente",
                    "LBL_RENTAL":"Devis de location",
                    "LBL_SAV":"Devis de maintenance",
                    "LBL_CDE":"Bon de commande",
                    "LBL_INVOICE":"Facture",
                    "LBL_ADVANCE":"Facture d'acompte",
                    "LBL_CREDIT":"Avoir",
                    "SAL_LBL_LOST":"Sans suite",
                    "SAL_LBL_RUNNING":"En cours",
                    "SAL_LBL_SOLD":"Terminé",
                    "INV_LBL_DRAFT":"Brouillon",
                    "INV_LBL_RUNNING":"En cours",
                    "INV_LBL_SOLD":"Soldée",                    
                }
        }
    }
}

export default new lang()