<template>
  <div class="boo-page">
    <div class="boo-header">
      <div class="boo-header-left">
        <!-- <a href="https://www.procust.fr" target="_blank"><img src="../assets/logo.png" alt=""></a> -->
      </div>
      <div class="boo-header-center">
        <a href="https://www.procust.fr" target="_blank"><img src="../assets/logo.png" alt=""></a>
      </div>
      <div class="boo-header-right">
        <!-- <ul>
          <li><i class='far fa-user'></i></li>
          <li><i class='fa fa-shopping-cart'></i></li>
        </ul> -->
      </div>
    </div>
    <div v-if="view=='form'">
      <div class="boo-formulaire">
        <div class="boo-formulaire-logo">
          <!-- <img src="../assets/procust_cryonov.jpg" alt=""> -->
          <img :src="product.logo" :alt="product.title">
        </div>
        <div class="boo-formulaire-content">
          <h2>{{product.title}}</h2>
          <h3>Tarif : {{$filters.money(product.price,lang)}} / jours</h3>
          <p>{{product.note}}</p>
          <h4>Veuillez sélectionner les <b>dates de location souhaitées</b> (une période de 10 jours minimum est requise) ainsi que le <b>type d'enveloppe</b> adaptée à votre besoin.</h4>
          <label>Date de retrait</label>
          <div class="boo-formulaire-dates">
            <div class="boo-formulaire-dates-input">
              <i class="fa fa-calendar-alt"></i>
              <datepicker v-model="period.rstartdate" :weekends="false" :disabled_dates="[]" :disabled="false" :changed="startdate_changed" ></datepicker>
            </div>
            <div class="boo-formulaire-dates-input">
              <i class="fa fa-clock"></i>
              <select>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
              </select>
            </div>            
          </div>
          <label>Date de retour</label>
          <div class="boo-formulaire-dates">
            <div class="boo-formulaire-dates-input">
              <i class="fa fa-calendar-alt"></i>
              <datepicker v-model="period.renddate" :weekends="false" :disabled_dates="[]" :disabled="false" :changed="enddate_changed" ></datepicker>
            </div>
            <div class="boo-formulaire-dates-input">
              <i class="fa fa-clock"></i>
              <select>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
              </select>
            </div>
          </div>
          <!-- <div v-if="product.articles.length>0">
            <label style="display:block;">Pour votre enveloppe, vous souhaitez :</label>
            <input type="radio" id="rrental" v-model="selection_type_enveloppe" value="rental"><label for="rrental"> Récupérer une enveloppe incluse dans la location</label><br/>
            <input type="radio" id="rsale" v-model="selection_type_enveloppe" value="sale"><label for="rsale"> Acheter une enveloppe</label>
            <br/>
            <br/>
          </div> -->
          <label v-if="selection_type_enveloppe=='rental'">Type d'enveloppe inclus dans la location</label>
          <select v-if="selection_type_enveloppe=='rental'" v-model="accessory" class="boo-formulaire-select">
            <option value="00000000-0000-0000-0000-000000000000">Veuillez sélectionner un type d'enveloppe</option>
            <option v-for="ac of product.accessories" :key="ac.id" :value="ac.id" :disabled="ac.qtydispo==0">{{ ac.note }} <label v-if="ac.qtydispo==0">(non disponible)</label></option>
          </select>
          <label v-if="selection_type_enveloppe=='sale'">Type d'enveloppe à l'achat</label>
          <select v-if="selection_type_enveloppe=='sale'" v-model="article" class="boo-formulaire-select">
            <option value="00000000-0000-0000-0000-000000000000">Veuillez sélectionner un type d'enveloppe</option>
            <option v-for="ac of product.articles" :key="ac.id" :value="ac.id" :disabled="ac.qtydispo==0">{{ ac.note }} - Prix : {{ $filters.money(ac.total_line,lang) }} <label v-if="ac.qtydispo==0">(non disponible)</label></option>
          </select>
          <div class="boo-formulaire-total">
            <div class="boo-formulaire-total-info">
              <label>Montant de la location ({{product.duration_coef}} jours)</label>
            </div>
            <div class="boo-formulaire-total-price">
              <label>{{$filters.money(total,lang)}}</label>
            </div>
          </div>
          <label class="boo-formulaire-error" v-if="is_error">{{ error_message }}</label>
          <button @click="valid_item()" style="margin-top:30px;">Louez votre appareil <i class="fa fa-arrow-right"></i></button>          
        </div>        
      </div>
      <div class="boo-infobulle">
        <div class="boo-infobulle-content">
          <h2>Vous souhaitez acheter cet appareil ?</h2>
          <label>N'hésitez pas à nous contacter, nous avons aussi une solution pour vous !</label>
          <button @click="bt_go_contact">Nous contacter <i class="fa fa-arrow-right"></i></button>
        </div>      
      </div>      
    </div>    
    <div class="boo-footer-pub">
      <div class="boo-footer-pub-content">
        <div class="boo-footer-pub-item">
          <label><i class='fas fa-credit-card'></i> Paiement sécurisé par CB</label>
        </div>
        <div class="boo-footer-pub-item">
          <label><i class='fa fa-box'></i> Retrait et retour possible au cabinet</label>
        </div>
        <div class="boo-footer-pub-item">
          <label><i class='fa fa-truck'></i> Livraison et retour possible par transporteur</label>          
        </div>
      </div>      
    </div>
    <div class="boo-footer">
      <h2>Location en ligne</h2>
      <img src="../assets/logo_blanc.png" alt="">
      <h3>By Sud Orthopédie</h3>
      <div class="boo-footer-info">
        <div class="boo-footer-info-left">
          <ul>
            <li><a href="#" target="_blank">Mentions légales</a></li>
            <li><a href="#" target="_blank">Conditions Générales de Location</a></li>
            <li><a href="https://www.procust.fr" target="_blank">procust.fr</a></li>
          </ul>
        </div>
        <div class="boo-footer-info-right">
          <label>&copy; 2024 Procust | Site réalisé par <a href="https://www.lokisi.com" target="_blank">LoKisi</a></label>
        </div>
      </div>
    </div>
    <loadingbox v-if="loading"></loadingbox>
  </div>
</template>

<script>
import translation from "../core/data/lang";
import datepicker from "../core/controls/ui-datepicker";
import loadingbox from "../core/controls/ui-loadingbox";
import apibooking from "../core/data/booking";
import {store} from "../core/data/store";

export default {
  components:{ 
    loadingbox,datepicker
  },
  data(){
    return {
      loading:false,
      resweb: {},
      lang:'fr-FR',
      view:'form',
      product:{},
      gridperiod:'',
      accessory:'',
      article:'',
      period:{},
      selection_type_enveloppe:'rental',
      is_error:false,
      error_message:'Vous devez saisir une période de minimum 10 jours et un type d\'enveloppe pour louer cet appareil.'
    }
  },
  computed: {
    lg: function(){
      return translation.get(this.lang);
    },
    total:function(){
      if (this.selection_type_enveloppe=='rental'){
        return this.product.total_line;
      }else{
        var sel = this.product.articles.find(x=>x.id==this.article);
        if (sel!=undefined){
          return (this.product.total_line + sel.total_line);
        }else{
          return this.product.total_line;
        }        
      }
    }
  },
  created: async function(){
    var tmain = this;
    store.get_document();
    if (JSON.stringify(store.document.product)=='{}'){
      tmain.$router.push({path:'/'});
    }else{
      document.title = store.title;
      tmain.product = store.document.product;
      tmain.accessory = store.document.accessory;
      tmain.article = store.document.article;
      tmain.period = store.document.period;
      await this.refresh_ui();
    }
  },
  methods:{
    refresh_ui: async function(){
      var tmain = this;
      var rep = await apibooking.product_list(store.document.period);
      if (rep.code==0){
        tmain.gridperiod = rep.data.id_gridperiod;
        tmain.product = rep.data.products.find(x=>x.id==tmain.product.id);
        if (tmain.product.id ==="b423163b-d4ac-4a55-86b7-9e9750d11019"){
          tmain.selection_type_enveloppe="sale";
        }
        if (tmain.product.duration_coef<0){
          tmain.product.duration_coef = 0;
          tmain.product.total_line = 0;
        }
      }
    },
    startdate_changed: async function(){
      store.document.period = this.period;
      store.set_document(store.document);
      await this.refresh_ui();
    },
    enddate_changed: async function(){
      store.document.period = this.period;
      store.set_document(store.document);
      await this.refresh_ui();
    },
    valid_item: async function(){
      var tmain = this;
      tmain.is_error=false;
      if (tmain.product.duration_coef<10 || (tmain.selection_type_enveloppe=='rental' && tmain.accessory=='00000000-0000-0000-0000-000000000000') || (tmain.selection_type_enveloppe=='sale' && tmain.article=='00000000-0000-0000-0000-000000000000')){
        tmain.error_message = 'Vous devez saisir une période de minimum 10 jours et un type d\'enveloppe pour louer cet appareil.';
        tmain.is_error=true;
      }else{
        store.document.period = this.period;
        store.document.accessory = this.selection_type_enveloppe=='rental' ? this.accessory : '00000000-0000-0000-0000-000000000000';
        store.document.article = this.selection_type_enveloppe=='sale' ? this.article : '00000000-0000-0000-0000-000000000000';
        store.document.product = this.product;
        store.document.gridperiod = this.gridperiod;
        store.set_document(store.document);
        var rep = await apibooking.product_list(store.document.period);
        if (rep.data.products[0].qtydispo<=0){
          tmain.error_message = 'Cet appareil n\'est pas disponible pour la période indiquée. Merci de nous contacter soit par téléphone au 07 78 07 55 23 soit par mail à contact@procust.fr.';
          tmain.is_error=true;
        }else{
          this.$router.push({path:'/basket'});
        }        
      }
    },
    bt_go_contact: function(){
      window.open("https://www.procust.fr/contact/",'_blank');
    }
  }
}
</script>