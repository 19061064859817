<template>
    <div>
        <div class='boo-background'></div>
        <div class="boo-loadingbox">
            <div class="boo-loadingbox-form">
                <i class="fa fa-spinner fa-spin"></i>
                <label>Veuillez patienter</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
    },
    data(){
        return {
            
        };
    },
    created() {
        
    },
    methods:{
        
    }
}
</script>