import { createWebHashHistory, createRouter} from 'vue-router'
import home from "./components/procust-home"
import item from "./components/procust-item"
import basket from "./components/procust-basket"
import payment from "./components/procust-payment"
import page_error from "./components/page-error"

const routes = [
    {
        path:"/",
        component: home
    },
    {
        path:"/item",
        component: item
    },
    {
        path:"/basket",
        component: basket
    },
    {
        path:"/payment",
        component: payment
    },
    {
        path:"/:catchAll(.*)",
        component: page_error
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;