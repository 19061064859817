<template>
  <div class="boo-page">
    <div class="boo-header">
      <div class="boo-header-left">
        <!-- <a href="https://www.procust.fr" target="_blank"><img src="../assets/logo.png" alt=""></a> -->
      </div>
      <div class="boo-header-center">
        <a href="https://www.procust.fr" target="_blank"><img src="../assets/logo.png" alt=""></a>
      </div>
      <div class="boo-header-right">
        <!-- <ul>
          <li><i class='far fa-user'></i></li>
          <li><i class='fa fa-shopping-cart'></i></li>
        </ul> -->
      </div>
    </div>
    <div v-if="view=='start'">
      <div class="boo-title">
        <h2>Veuillez choisir l'appareil que vous souhaitez louer</h2>
      </div>
      <div class="boo-subtitle">
        <h2>Nos appareils disponibles</h2>
      </div>
      <div class="boo-list">
        <div class="boo-list-item" v-for="p of products" :key="p.id">
          <img :src="p.logo" alt="">
          <label>{{p.title}}</label>
          <button @click="select_product(p)">Louer cet appareil <i class="fa fa-arrow-right"></i></button>
          <!-- <button style="background-color:var(--C2);" v-if="p.qtydispo<=0">Rupture de stock <i class="fa fa-ban"></i></button> -->
        </div>
      </div>
    </div>    
    <div class="boo-footer-pub">
      <div class="boo-footer-pub-content">
        <div class="boo-footer-pub-item">
          <label><i class='fas fa-credit-card'></i> Paiement sécurisé par CB</label>
        </div>
        <div class="boo-footer-pub-item">
          <label><i class='fa fa-box'></i> Retrait et retour possible au cabinet</label>
        </div>
        <div class="boo-footer-pub-item">
          <label><i class='fa fa-truck'></i> Livraison et retour possible par transporteur</label>          
        </div>
      </div>      
    </div>
    <div class="boo-footer">
      <h2>Location en ligne</h2>
      <img src="../assets/logo_blanc.png" alt="">
      <h3>By Sud Orthopédie</h3>
      <div class="boo-footer-info">
        <div class="boo-footer-info-left">
          <ul>
            <li><a href="#" target="_blank">Mentions légales</a></li>
            <li><a href="#" target="_blank">Conditions Générales de Location</a></li>
            <li><a href="https://www.procust.fr" target="_blank">procust.fr</a></li>
          </ul>
        </div>
        <div class="boo-footer-info-right">
          <label>&copy; 2024 Procust | Site réalisé par <a href="https://www.lokisi.com" target="_blank">LoKisi</a></label>
        </div>
      </div>
    </div>
    <loadingbox v-if="loading"></loadingbox>
  </div>
</template>

<script>
import translation from "../core/data/lang";
import loadingbox from "../core/controls/ui-loadingbox";
import apibooking from "../core/data/booking";
import {store} from "../core/data/store";

export default {
  components:{ 
    loadingbox
  },
  data(){
    return {
      loading:false,
      resweb: {},
      lang:'fr-FR',
      view:'start',
      period:{},
      accessory:{},
      products:[]
    }
  },
  computed: {
    lg: function(){
      return translation.get(this.lang);
    }
  },
  created: async function(){
    var tmain = this;
    document.title = store.title;
    localStorage.clear();
    store.get_document();
    tmain.period = store.document.period;
    var rep = await apibooking.product_list(store.document.period);
    if (rep.code==0){
      tmain.products = rep.data.products;
    }
  },
  methods:{
    select_product: function(prod){
      store.document.product = prod;
      store.document.accessory = '00000000-0000-0000-0000-000000000000';
      store.document.release =  '00000000-0000-0000-0000-000000000000';
      store.document.return =  '00000000-0000-0000-0000-000000000000';
      store.set_document(store.document);
      this.$router.push({path:'/item'});
    }
  }
}
</script>